<template>
  <div>
    <v-card class="mx-auto my-3">
      <v-container class="articles">
        <v-row
          :style="{
            'background-color': getNitrateColor(article.nitrateLevel)
            }"
          class="pa-1 relative"
        >
          <v-col cols="4" class="text-left">Study Nitrate Level</v-col>
          <v-col cols="7" class="text-left">{{ article.nitrateLevel }} {{article.nitrateLevelNote}}</v-col>
          <div class="close-btn">
            <close-window></close-window>
          </div>
        </v-row>
        <v-row class="pa-2">
          <v-col
            v-if="article.title"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Title</v-col
          >
          <v-col v-if="article.title" :cols="cols.second" class="text-left">{{
            article.title
          }}</v-col>

          <v-col
            v-if="article.diseases && article.diseases.length"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Disease(s)</v-col
          >
          <v-col
            v-if="article.diseases && article.diseases.length"
            :cols="cols.second"
            class="text-left"
          >
            {{ article.diseases.join(', ') }}
          </v-col>

          <v-col
            v-if="article.journal"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Peer-Reviewed Journal</v-col
          >
          <v-col v-if="article.journal" :cols="cols.second" class="text-left">
            <a
              :href="`/journals?name=${article.journal}`"
              target="_journals"
              class="decoration-none"
              >{{ article.journal }}
              <v-icon class="href--text">{{ icons.linkIcon }}</v-icon></a
            >
          </v-col>

          <v-col
            v-if="article.datePublished"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Published</v-col
          >
          <v-col
            v-if="article.datePublished"
            :cols="cols.second"
            class="text-left"
          >
            {{ article.datePublished }}
          </v-col>

          <v-col
            v-if="article.excerpts && article.excerpts.length"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Excerpt(s)</v-col
          >
          <v-col
            v-if="article.excerpts && article.excerpts.length"
            :cols="cols.second"
            class="text-left"
          >
            <div
              v-for="(item, index) in article.excerpts"
              :key="index"
              :class="index != article.excerpts.length - 1 ? 'mb-3' : ''"
            >
              <q>{{ item }}</q>
            </div>
          </v-col>

          <v-col
            v-if="article.authors"
            :cols="cols.first"
            class="text-left article-key"
            :sm="cols.sm"
            >Author(s)</v-col
          >
          <v-col v-if="article.authors" :cols="cols.second" class="text-left">{{
            article.authors
          }}</v-col>

          <v-col :cols="cols.first" :sm="cols.sm" class="text-left article-key"
            >Abstract</v-col
          >
          <v-col :cols="cols.second" class="text-left">
            <p class="d-inline" v-html="abstract"></p>
            <span
              class="d-inline ml-2 cursor-pointer href--text"
              @click="abstract_full = !abstract_full"
            >
              <v-icon v-if="abstract_full" color="primary">mdi-chevron-up</v-icon>
              <span v-else>More...</span>
            </span>
          </v-col>

          <v-col
            v-if="article.url"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Full Article</v-col
          >
          <v-col v-if="article.url" :cols="cols.second" class="text-left"
            ><a
              target="_blank"
              @click="newWindow(article.url)"
              class="decoration-none"
              >Full article
              <v-icon class="href--text">{{ icons.linkIcon }}</v-icon></a
            >
            has {{ article.pages }} pdf pages
            <a
              @click="newWindow(article.url)"
              class="decoration-none"
              target="_blank"
              ><v-icon class="cursor-pointer href--text"
                >mdi-file-pdf-outline</v-icon
              ></a
            ></v-col
          >

          <v-col
            v-if="article.keywords.length"
            :sm="cols.sm"
            :cols="cols.first"
            class="text-left article-key"
            >Keywords</v-col
          >
          <v-col
            v-if="article.keywords.length"
            :cols="cols.second"
            class="text-left"
          >
            {{ article.keywords.join(', ') }}
          </v-col>

          <v-col
            v-if="article.referenceKey"
            :cols="cols.first"
            :sm="cols.sm"
            class="text-left article-key"
            >Key</v-col
          >
          <v-col
            v-if="article.referenceKey"
            :cols="cols.second"
            class="text-left"
            >{{ article.referenceKey }}</v-col
          >
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
import { truncate } from '../../utils/string'
export default {
  mixins: [utils],
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  mounted() {},
  filters: {
    author(author) {
      if (!author) {
        return ''
      }
      let name = ''
      if (typeof author === 'string') {
        name = author || ''
      } else if (typeof author === 'object') {
        name = author[0] | ''
      }
      return name.split(' ').pop()
    }
  },
  computed: {
    abstract() {
      if (this.abstract_full) {
        return this.article.abstract
      } else {
        return truncate(this.article.abstract, 200, true)
      }
    },
    showAuthor() {
      if (!this.article) {
        return false
      }
      if (!this.article.authorPrimary) {
        return false
      }
      return true
    }
  },

  data: () => ({
    cols: {
      first: 4,
      second: 8,
      sm: 3
    },
    abstract_full: false,
    icons: {
      linkIcon: 'mdi-open-in-new'
    }
  }),
  methods: {
    close() {
      window.close()
    }
  }
}
</script>
<style>
.articles p {
  display: inline !important;
}
.article-key {
  font-weight: bold;
}
.close-btn{
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 15px;
}
</style>